import { useState, useEffect, useCallback } from "react";
import { withRequiredAuthInfo } from "@propelauth/react";
import { useNodeApi } from "../../hooks/useNodeApi";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Overview } from "./Overview";
import { RecentUsage } from "./RecentUsage";
import { UserMetricResponse, MetricCategory } from "../../services/api";

export const Dashboard = withRequiredAuthInfo(({ accessToken, user }) => {
  const { getUserMetricsHistory } = useNodeApi(accessToken);
  const [metrics, setMetrics] = useState<UserMetricResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchMetrics = useCallback(async () => {
    if (!accessToken) return;

    try {
      setIsLoading(true);
      setError(null);

      const [extractMetrics, compareMetrics, workflowMetrics, auditMetrics] = await Promise.all([
        getUserMetricsHistory(MetricCategory.EXTRACT, 30),
        getUserMetricsHistory(MetricCategory.COMPARE, 30),
        getUserMetricsHistory(MetricCategory.WORKFLOW, 30),
        getUserMetricsHistory(MetricCategory.AUDIT, 30)
      ]);

      const combinedMetrics = {
        metrics: [
          ...extractMetrics.metrics,
          ...compareMetrics.metrics,
          ...workflowMetrics.metrics,
          ...auditMetrics.metrics
        ],
        total_documents:
          extractMetrics.total_documents +
          compareMetrics.total_documents +
          workflowMetrics.total_documents +
          auditMetrics.total_documents,
        total_requests:
          extractMetrics.total_requests +
          compareMetrics.total_requests +
          workflowMetrics.total_requests +
          auditMetrics.total_requests,
        period_start: extractMetrics.period_start,
        period_end: extractMetrics.period_end
      };

      setMetrics(combinedMetrics);
    } catch (err) {
      console.error('Error fetching metrics:', err);
      setError('Failed to load metrics');
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!accessToken) return;

    let isSubscribed = true;

    const loadData = async () => {
      try {
        await fetchMetrics();
      } catch (err) {
        if (isSubscribed) {
          console.error('Error loading metrics:', err);
          setError('Failed to load metrics');
        }
      }
    };

    loadData();

    return () => {
      isSubscribed = false;
    };
  }, [accessToken, fetchMetrics]);

  const userName = user?.firstName || user?.email?.split('@')[0] || 'User';

  return (
    <div className="flex-grow flex flex-col h-full relative">
      <div className="max-w-6xl mx-auto px-2 py-8">
        <div className="mb-6">
          <h1 className="text-xl font-semibold">Welcome back, {userName}!</h1>
          <p className="text-sm text-muted-foreground mt-1">
            Here is your updated monthly usage data.
          </p>
        </div>

        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-7">
          <Card className="col-span-4 bg-white border border-gray-200 shadow-sm">
            <CardHeader>
              <CardTitle>Overview</CardTitle>
            </CardHeader>
            <CardContent className="pl-2">
              <Overview metrics={metrics} />
            </CardContent>
          </Card>
          <Card className="col-span-3 bg-white border border-gray-200 shadow-sm">
            <CardHeader>
              <CardTitle>Recent Usage</CardTitle>
            </CardHeader>
            <CardContent>
              <RecentUsage metrics={metrics} isLoading={isLoading} error={error} />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
});