import { useState, useMemo } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';
import { PDFPreviewSlider } from '../compare/PDFPreviewSlider';
import { TableCell as TableCellType, Citation } from '../../types/table';
import { cn } from '../../lib/utils';
import ReactMarkdown from 'react-markdown';
import { markdownComponents } from '../core/MarkdownComponents';

interface GenericTableProps {
  headers: string[];
  rows: { cells: TableCellType[] }[];
  onCitationClick?: (citations: Citation[]) => void;
  pdfUrl?: string | null;
  filteredRows?: number[];
  title?: string;
}

export function GenericTable({
  headers,
  rows,
  onCitationClick,
  pdfUrl,
  filteredRows,
  title = "Table Results",
}: GenericTableProps) {
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [selectedCitations, setSelectedCitations] = useState<Citation[] | null>(null);

  const handleCellClick = (cell: TableCellType) => {
    if (cell.citations_with_documents?.length) {
      setSelectedCitations(cell.citations_with_documents);
      setIsSliderOpen(true);
      onCitationClick?.(cell.citations_with_documents);
    }
  };

  const columnWidths = useMemo(() => {
    return headers.map((_, columnIndex) => {
      const maxLength = Math.max(
        headers[columnIndex].length,
        ...rows.map(row => {
          const cellValue = row.cells[columnIndex]?.value;
          return String(cellValue ?? '').length;
        })
      );

      if (maxLength > 250) {
        return 'min-w-[600px]';
      }
      if (maxLength > 100) {
        return 'min-w-[350px]';
      }
      if (maxLength > 25) {
        return 'min-w-[250px]';
      }
      return '';
    });
  }, [rows, headers]);

  const displayedRows = useMemo(() => {
    return filteredRows
      ? rows.filter((_, index) => filteredRows.includes(index))
      : rows;
  }, [rows, filteredRows]);

  return (
    <>
      <div className="w-full space-y-4">
        <div className="space-y-2">
          <h2 className="text-xl font-semibold tracking-tight">{title}</h2>
        </div>

        <div className="w-full overflow-x-auto">
          <Table className="border-collapse border border-gray-200 w-full">
            <TableHeader>
              <TableRow>
                {headers.map((header, index) => (
                  <TableHead
                    key={index}
                    className={cn(
                      "border border-gray-200 bg-gray-50 p-4",
                      columnWidths[index]
                    )}
                  >
                    {header}
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {displayedRows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.cells.map((cell, cellIndex) => (
                    <TableCell
                      key={cellIndex}
                      className={cn(
                        "border border-gray-200 p-4",
                        columnWidths[cellIndex],
                        cell.citations_with_documents?.length && "cursor-pointer hover:bg-gray-50"
                      )}
                      onClick={() => handleCellClick(cell)}
                    >
                      <div className="whitespace-pre-wrap break-words">
                        <ReactMarkdown
                          components={markdownComponents}
                          className="prose prose-sm max-w-none"
                        >
                          {cell.value || '-'}
                        </ReactMarkdown>
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      {pdfUrl && (
        <PDFPreviewSlider
          isOpen={isSliderOpen}
          onClose={() => {
            setIsSliderOpen(false);
            setSelectedCitations(null);
          }}
          pdfUrl={pdfUrl}
          citations={selectedCitations?.map(citation => ({
            citation: citation.citation,
            state: 'match'
          })) ?? null}
          isLoading={false}
        />
      )}
    </>
  );
}
