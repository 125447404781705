import { useState, useRef, useMemo, useEffect, useCallback } from "react"
import { Button } from "../ui/button"
import { Sparkle, Search, ChevronUp, ChevronDown, ChevronLeft, ChevronRight, MoreHorizontal, MoreVertical, Settings2, Plus, Pencil } from "lucide-react"
import { Input } from "../ui/input"
import { useNodeApi } from '../../hooks/useNodeApi';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table"
import { Checkbox } from "../ui/checkbox"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"
import av1 from '../../assets/avatars/av1.svg';
import { useNavigate } from 'react-router-dom'
import { UserDocument, GetUserSchemasResponse, ExtractMultiLogResponse } from '../../services/api';
import toast from 'react-hot-toast';
import { UserClass, withRequiredAuthInfo } from "@propelauth/react";
import { usePolling } from "../../lib/usePolling";
import { POLLING_CONFIG } from "../../config/pollingConfig";
import { UploadDialog } from "../UploadDialog"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuCheckboxItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "../ui/dropdown-menu"
import { Trash2 } from "lucide-react"

type ExtractData = {
  id: string;
  personWhoStartedIt: string;
  personImage: string;
  senderName: string;
  templateName: string;
  numberOfDocuments: number;
  numberOfFields: number;
  date: string;
  time: string;
  isDeleted?: boolean;
};

type Schema = {
  name: string;
  value: string;
  schema: string;
  isCustom?: boolean;
  options?: { outputAsGrid?: boolean };
  isDeleted?: boolean;
};

type Template = {
  name: string;
  value: string;
  schema: string;
  isCustom?: boolean;
  options?: { outputAsGrid?: boolean };
};

const schemaTemplates: Schema[] = [
  { name: "Create your own", value: "create_your_own", schema: "", isCustom: true },
];

const pdfFileTypes = {
  mimeTypes: ['application/pdf'],
  errorMessage: 'Please upload PDF files only.'
};


const columnFilterOptions = [
  { id: "senderName", label: "Sender Name" },
  { id: "templateName", label: "Template" },
  { id: "numberOfDocuments", label: "Document Count" },
  { id: "numberOfFields", label: "Fields" },
  { id: "date", label: "Date" },
  { id: "time", label: "Time" },
];

const regularSchemaTemplates = schemaTemplates.filter(t => !t.isCustom);
const createYourOwnTemplate = schemaTemplates.find(t => t.isCustom);

export const Extract = withRequiredAuthInfo(({ accessToken, userClass }: { accessToken: string | null, userClass: UserClass | null }) => {
  const navigate = useNavigate()
  const topComponentRef = useRef<HTMLDivElement>(null)
  const { uploadDocument, runMultiExtraction, getMultiExtractLogsHistory, deleteDocument, fetchUserSchemas, fetchUserSchemaById, deleteSchema, deleteLogByCategory } = useNodeApi(accessToken);
  const [extractLogs, setExtractLogs] = useState<ExtractMultiLogResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState<{ key: keyof ExtractData; direction: 'asc' | 'desc' | null }>({ key: 'id', direction: null });

  const [visibleColumns, setVisibleColumns] = useState(
    columnFilterOptions.map(col => ({ ...col, visible: true }))
  );

  const handleColumnToggle = (columnId: string, isChecked: boolean) => {
    setVisibleColumns(prev =>
      prev.map(col => (col.id === columnId ? { ...col, visible: isChecked } : col))
    );
  };

  const lastPollTimeRef = useRef(0);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedSchema, setSelectedSchema] = useState<Schema | null>(null);
  const [searchQuery, setSearchQuery] = useState("");

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const [savedSchemas, setSavedSchemas] = useState<GetUserSchemasResponse[]>([]);
  const hasLoadedSchemas = useRef(false);

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [schemaToDelete, setSchemaToDelete] = useState<string | null>(null);

  useEffect(() => {
    const loadSavedSchemas = async () => {
      if (!accessToken) return;
      if (hasLoadedSchemas.current) return;
      hasLoadedSchemas.current = true;

      try {
        const schemas = await fetchUserSchemas("extract");
        setSavedSchemas(schemas);
      } catch (error) {
        console.error("Failed to load schemas:", error);
        hasLoadedSchemas.current = false;
      }
    };

    loadSavedSchemas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allSchemas = useMemo(() => {
    return [
      ...regularSchemaTemplates.map(template => ({
        name: template.name,
        value: template.value,
        schema: template.schema,
        isCustom: template.isCustom,
        options: template.options
      })),
      ...savedSchemas
        .filter(schema => !schema.isDeleted)
        .map(schema => ({
          name: schema.name,
          value: schema._id,
          schema: schema.json_schema,
          isCustom: false,
          options: schema.options
        })),
      ...(createYourOwnTemplate ? [createYourOwnTemplate] : [])
    ];
  }, [savedSchemas]);

  const getSelectedSchema = useCallback((value: string): {
    name: string;
    value: string;
    schema: string;
    options?: { outputAsGrid?: boolean }
  } | undefined => {
    const schema = allSchemas.find(s => s.value === value);
    if (!schema) return undefined;

    return {
      name: schema.name,
      value: schema.value,
      schema: schema.schema,
      options: schema.options
    };
  }, [allSchemas]);

  const handleSchemaClick = (template: Template) => {
    if (template.isCustom) {
      const org = userClass?.getOrgs()[0];
      const isFurtherAI = org?.orgMetadata["6296ccd4-3aa5-4475-bb9b-4a005612990b_FAI"];

      if (!isFurtherAI) {
        toast.error("Contact your admin to enable this feature.");
        return;
      }
      navigate('/extract/create-template', { state: { fromExtract: true } });
    } else {
      setSelectedSchema(template);
      setIsUploadDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setIsUploadDialogOpen(false);
    setSelectedSchema(null);
  };

  const handleRowSelection = (id: string, isChecked: boolean) => {
    setSelectedRows(prev =>
      isChecked
        ? [...prev, id]
        : prev.filter(rowId => rowId !== id)
    );
  };

  const handleSelectAllRows = (checked: boolean) => {
    if (checked) {
      const newSelectedRows = selectedRows.slice();
      paginatedData.forEach(row => {
        if (!newSelectedRows.includes(row.id)) {
          newSelectedRows.push(row.id);
        }
      });
      setSelectedRows(newSelectedRows);
    } else {
      const currentPageIds = paginatedData.map(row => row.id);
      setSelectedRows(selectedRows.filter(id => !currentPageIds.includes(id)));
    }
  };

  const requestSort = (key: keyof ExtractData) => {
    setSortConfig((currentConfig) => {
      if (currentConfig.key === key) {
        if (currentConfig.direction === 'asc') {
          return { key, direction: 'desc' };
        }
        if (currentConfig.direction === 'desc') {
          return { key, direction: null };
        }
      }
      return { key, direction: 'asc' };
    });
  };

  usePolling(
    () => {
      setIsHistoryLoading(true);
      return getMultiExtractLogsHistory();
    },
    {
      interval: POLLING_CONFIG.INITIAL_INTERVAL,
      maxInterval: POLLING_CONFIG.MAX_INTERVAL,
      backoffMultiplier: POLLING_CONFIG.BACKOFF_MULTIPLIER,
      enabled: true,
      lastPollTimeRef,
      onSuccess: (logs) => {
        setExtractLogs(logs);
        setIsHistoryLoading(false);
      },
      onError: (error) => {
        console.error("Failed to fetch extract logs:", error);
        setIsHistoryLoading(false);
      }
    }
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const getMultiTemplateName = (log: ExtractMultiLogResponse): string => {
    try {
      const schemaJson = JSON.parse(log.request?.user_schema || '{}');
      return schemaJson.title || 'Other';
    } catch (error) {
      console.error('Error parsing schema:', error);
      return 'Other';
    }
  };

  const formatDate = (created_at: string): string => {
    const startDate = new Date(created_at + "Z");
    return startDate.toLocaleDateString();
  };

  const formatTime = (created_at: string): string => {
    const startDate = new Date(created_at + "Z");
    return startDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const sortedAndFilteredData: ExtractData[] = useMemo(() => {
    return extractLogs
      .filter(log => !log.isDeleted)
      .map(log => ({
        id: log._id,
        personWhoStartedIt: log.owner_uid,
        personImage: av1,
        senderName: log.owner_name || '',
        templateName: getMultiTemplateName(log),
        numberOfDocuments: log.request.documents.length,
        numberOfFields: log.result?.results?.reduce((total, result) =>
          total + Object.keys(result.data || {}).length, 0) || 0,
        date: formatDate(log.created_at),
        time: formatTime(log.created_at),
        isDeleted: log.isDeleted || false
      }))
      .filter(row => {
        if (!searchQuery) return true;
        return Object.values(row)
          .some(value =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
          );
      })
      .sort((a, b) => {
        if (sortConfig.key && sortConfig.direction) {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
        }
        return 0;
      });
  }, [extractLogs, searchQuery, sortConfig]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    return sortedAndFilteredData.slice(startIndex, startIndex + rowsPerPage);
  }, [sortedAndFilteredData, currentPage, rowsPerPage]);

  const columnWidths = {
    checkbox: "w-[60px]",
    senderName: "w-[160px]",
    templateName: "w-[160px]",
    numberOfDocuments: "w-[140px]",
    numberOfFields: "w-[140px]",
    date: "w-[120px]",
    time: "w-[120px]",
    actions: "w-[60px]"
  } as const;

  const SortableTableHead = ({ children, sortKey, className }: {
    children: React.ReactNode,
    sortKey: keyof ExtractData,
    className?: string
  }) => (
    <TableHead className={` ${className}`}>
      <Button
        variant="ghost"
        onClick={() => requestSort(sortKey)}
        className="hover:bg-transparent w-full justify-start -ml-4 px-4 h-full whitespace-nowrap"
        disabled={isHistoryLoading}
      >
        <span>{children}</span>
        <span className="ml-2">
          {sortConfig.key === sortKey && sortConfig.direction === 'asc' && (
            <ChevronUp className="h-4 w-4" />
          )}
          {sortConfig.key === sortKey && sortConfig.direction === 'desc' && (
            <ChevronDown className="h-4 w-4" />
          )}
        </span>
      </Button>
    </TableHead>
  );

  const handleRowClick = (extract: ExtractData) => {
    navigate(`/extract/logs/${extract.id}`)
  }

  const renderShimmerRows = () => {
    return Array(rowsPerPage).fill(0).map((_, index) => (
      <TableRow key={`shimmer-${index}`}>
        <TableCell className="border-y border-l border-gray-200">
          <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-12 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-12 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-12 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-20 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-16 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-r border-gray-200">
          <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
      </TableRow>
    ));
  };

  const handleUploadComplete = async (documents: UserDocument[]) => {
    await new Promise(resolve => setTimeout(resolve, 0));

    if (!selectedSchema) {
      toast.error('Please select a schema before running the extraction.');
      return;
    }

    try {
      const documentIds = documents.map(doc => doc._id);
      const schemaWithOptions = getSelectedSchema(selectedSchema.value);
      const extractLogId = await runMultiExtraction(
        documentIds,
        selectedSchema.schema,
        schemaWithOptions?.options
      );

      if (!extractLogId) {
        throw new Error('No valid extraction ID received');
      }

      navigate(`/extract/logs/${extractLogId}`);
    } catch (error) {
      console.error('Upload complete error:', error);
      const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
      toast.error(`Failed to initiate extraction: ${errorMessage}`);
    }
  };

  const handleDelete = async (id: string, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await deleteLogByCategory('extract-multi', id);
      setExtractLogs(prevLogs =>
        prevLogs.map(log =>
          log._id === id
            ? { ...log, isDeleted: true }
            : log
        )
      );
      toast.success('Successfully deleted');
    } catch (error) {
      console.error('Failed to delete extract log:', error);
      toast.error('Failed to delete');
    }
  };

  const handleDeleteSchema = async (schemaId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    setSchemaToDelete(schemaId);
    setIsDeleteConfirmOpen(true);
  };

  const confirmDeleteSchema = async () => {
    if (!schemaToDelete) return;

    try {
      setIsDeleteConfirmOpen(false);
      setSchemaToDelete(null);

      await deleteSchema(schemaToDelete);
      setSavedSchemas(prevSchemas =>
        prevSchemas.map(schema =>
          schema._id === schemaToDelete
            ? { ...schema, isDeleted: true }
            : schema
        )
      );

      hasLoadedSchemas.current = false;
      const updatedSchemas = await fetchUserSchemas("extract");
      setSavedSchemas(updatedSchemas);

      toast.success('Schema deleted successfully');
    } catch (error) {
      console.error('Failed to delete schema:', error);
      toast.error('Failed to delete schema');
    }
  };

  const handleEditSchema = async (schemaId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const schema = await fetchUserSchemaById(schemaId);

      navigate('/extract/create-template', {
        state: {
          fromExtract: true,
          editMode: true,
          schemaData: schema
        }
      });
    } catch (error) {
      console.error('Failed to edit schema:', error);
      toast.error('Failed to load schema');
    }
  };

  return (
    <div>
      <div className="max-w-6xl mx-auto px-2 py-8">
        <div ref={topComponentRef}>
          <h1 className="text-xl font-semibold mb-6">Extract</h1>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-4 mb-8">
            {allSchemas.map((template) => (
              <div key={template.value} className="relative">
                <Button
                  variant="outline"
                  size="lg"
                  className={`
                    h-28
                    w-full
                    flex flex-col items-center justify-center
                    hover:bg-gray-50
                    transition-colors
                    bg-white
                    shadow-sm
                    rounded-lg
                    border border-gray-200
                    ${selectedSchema?.value === template.value
                      ? 'ring-2 ring-blue-600 border-transparent'
                      : 'hover:border-gray-300'
                    }
                  `}
                  onClick={() => handleSchemaClick(template)}
                >
                  {template.isCustom ? (
                    <Plus className="h-6 w-6 text-blue-500 mb-2" />
                  ) : (
                    <Sparkle className="h-6 w-6 text-blue-500 mb-2" />
                  )}
                  <span className="text-sm font-medium text-gray-900">
                    {template.name}
                  </span>
                </Button>

                {!template.isCustom && !regularSchemaTemplates.some(t => t.value === template.value) && (
                  <div className="absolute top-2 right-2">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="ghost"
                          size="sm"
                          className="h-8 w-8 p-0 hover:bg-gray-100"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <MoreVertical className="h-4 w-4 text-gray-400" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent
                        className="w-40 p-1 bg-white"
                        align="start"
                        alignOffset={5}
                        sideOffset={8}
                        forceMount
                      >
                        <DropdownMenuItem
                          onClick={(e) => handleEditSchema(template.value, e)}
                          className="flex items-center px-2 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          <Pencil className="mr-2 h-4 w-4" />
                          <span>Edit Schema</span>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={(e) => handleDeleteSchema(template.value, e)}
                          className="flex items-center px-2 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-700"
                        >
                          <Trash2 className="mr-2 h-4 w-4" />
                          <span>Delete Schema</span>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="mt-4">
            <h1 className="text-xl font-semibold mb-4">History</h1>
            <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
              <div className="p-4">
                <div className="flex items-center justify-between mb-4">
                  <div className="relative">
                    <Input
                      type="text"
                      placeholder="Search"
                      className="pl-8 pr-4 py-1 w-48 bg-white border-gray-300 text-gray-900 focus:ring-0 h-9"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                  </div>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="outline"
                        size="sm"
                        className="h-9 flex items-center text-gray-900 border-gray-300"
                      >
                        <Settings2 className="h-4 w-4 mr-2" />
                        View
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-[200px] bg-white shadow-lg rounded-md">
                      <DropdownMenuLabel>Filter Columns</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      {columnFilterOptions.map((column) => (
                        <DropdownMenuCheckboxItem
                          key={column.id}
                          className="capitalize"
                          checked={visibleColumns.find((col) => col.id === column.id)?.visible}
                          onCheckedChange={(isChecked) => handleColumnToggle(column.id, isChecked)}
                        >
                          {column.label}
                        </DropdownMenuCheckboxItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>

                <div className="overflow-x-auto rounded-md border border-gray-200">
                  <Table className="table-fixed w-full">
                  <TableHeader>
                    <TableRow>
                      <TableHead className={`${columnWidths.checkbox}`}>
                        <Checkbox
                          checked={
                            paginatedData.length > 0 &&
                            paginatedData.every((row) => selectedRows.includes(row.id))
                          }
                          onCheckedChange={handleSelectAllRows}
                        />
                      </TableHead>
                      {visibleColumns
                        .filter((col) => col.visible)
                        .map((column) => (
                          <SortableTableHead
                            key={column.id}
                            sortKey={column.id as keyof ExtractData}
                            className={columnWidths[column.id as keyof typeof columnWidths]}
                          >
                            {column.label}
                          </SortableTableHead>
                        ))}
                      <TableHead className={`${columnWidths.actions}`} />
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {isHistoryLoading ? (
                      renderShimmerRows()
                    ) : (
                      paginatedData.map((row) => (
                        <TableRow
                          key={row.id}
                          onClick={() => handleRowClick(row)}
                          className="cursor-pointer hover:bg-gray-50"
                        >
                          <TableCell className={`pl-4 ${columnWidths.checkbox}`}>
                            <Checkbox
                              checked={selectedRows.includes(row.id)}
                              onCheckedChange={(checked) =>
                                handleRowSelection(row.id, checked as boolean)
                              }
                              onClick={(e) => e.stopPropagation()}
                            />
                          </TableCell>
                          {visibleColumns
                            .filter((col) => col.visible)
                            .map((column) => (
                              <TableCell
                                key={column.id}
                                className={`px-4 ${columnWidths[column.id as keyof typeof columnWidths]}`}
                              >
                                {row[column.id as keyof ExtractData]}
                              </TableCell>
                            ))}
                          <TableCell className={`px-4 ${columnWidths.actions}`}>
                            <DropdownMenu>
                              <DropdownMenuTrigger
                                asChild
                                onClick={(e) => e.stopPropagation()}
                              >
                                <Button
                                  variant="ghost"
                                  size="sm"
                                  className="h-8 w-8 p-0 hover:bg-gray-100"
                                >
                                  <MoreHorizontal className="h-4 w-4 text-gray-400" />
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent
                                className="w-40 p-1 bg-white"
                                align="end"
                                alignOffset={-5}
                                sideOffset={8}
                                forceMount
                              >
                                <DropdownMenuItem
                                  onClick={(e) => handleDelete(row.id, e)}
                                  className="flex items-center px-2 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-700"
                                >
                                  <Trash2 className="mr-2 h-4 w-4" />
                                  <span>Delete</span>
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                  </Table>
                </div>

                <div className="flex justify-between items-center mt-4">
                  <p className="text-sm text-gray-500">
                    {isHistoryLoading
                      ? <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
                      : `${selectedRows.length} of ${sortedAndFilteredData.length} row(s) selected.`
                    }
                  </p>
                  <div className="flex items-center space-x-2">
                    <span className="text-sm text-gray-500">Rows per page</span>
                    <Select value={rowsPerPage.toString()} onValueChange={(value) => setRowsPerPage(Number(value))}>
                      <SelectTrigger className="w-[70px] focus:ring-0 bg-white">
                        <SelectValue placeholder={rowsPerPage.toString()} />
                      </SelectTrigger>
                      <SelectContent className="bg-white">
                        <SelectItem value="5">5</SelectItem>
                        <SelectItem value="10">10</SelectItem>
                        <SelectItem value="20">20</SelectItem>
                      </SelectContent>
                    </Select>
                    <span className="text-sm text-gray-500">
                      Page {currentPage} of {Math.ceil(sortedAndFilteredData.length / rowsPerPage)}
                    </span>
                    <div className="flex space-x-1">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                      >
                        <ChevronLeft className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(sortedAndFilteredData.length / rowsPerPage)))}
                        disabled={currentPage === Math.ceil(sortedAndFilteredData.length / rowsPerPage)}
                      >
                        <ChevronRight className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isUploadDialogOpen && (
        <UploadDialog
          isOpen={isUploadDialogOpen}
          onClose={handleDialogClose}
          onUploadComplete={handleUploadComplete}
          uploadDocument={uploadDocument}
          deleteDocument={deleteDocument}
          selectedTemplate={selectedSchema?.value || ''}
          acceptedFileTypes={pdfFileTypes}
          category="extract"
          singleFileOnly={false}
          maxFiles={10}
          selectedSchema={getSelectedSchema(selectedSchema?.value || '')}
        />
      )}
      {isDeleteConfirmOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="fixed inset-0 bg-black/80"
            onClick={() => {
              setIsDeleteConfirmOpen(false);
              setSchemaToDelete(null);
            }}
          />
          <div className="relative z-50 w-full max-w-[425px] rounded-lg bg-white p-6 shadow-lg">
            <div className="flex flex-col space-y-1.5 text-center sm:text-left">
              <h2 className="text-lg font-semibold leading-none tracking-tight">Delete Schema</h2>
              <p className="text-sm text-muted-foreground">
                Are you sure you want to permanently delete this schema? This action cannot be undone.
              </p>
            </div>
            <div className="mt-6 flex justify-end gap-2">
              <Button
                variant="outline"
                onClick={() => {
                  setIsDeleteConfirmOpen(false);
                  setSchemaToDelete(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="destructive"
                onClick={confirmDeleteSchema}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
})
