import React from "react";
import { ScrollArea } from "../ui/scroll-area";
import DOMPurify from 'dompurify';

interface EmailDetailProps {
  emailBody: string;
  isEmbedded?: boolean;
  emailComponentTitle?: string;
  highlightText?: string | null;
}

class EmailContentErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <div className="text-red-500">Error rendering email content</div>;
    }
    return this.props.children;
  }
}

const sanitizeAndRenderHTML = (content: string, highlightText?: string | null) => {
  if (!content) return null;

  try {
    const decodedContent = content
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'");

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = decodedContent;

    const styleContents = Array.from(tempDiv.getElementsByTagName('style'))
      .map(style => style.textContent)
      .filter(Boolean);

    tempDiv.querySelectorAll('style').forEach(el => el.remove());

    const sanitizedContent = DOMPurify.sanitize(tempDiv.innerHTML, {
      ALLOWED_TAGS: [
        'p', 'br', 'strong', 'em', 'ul', 'ol', 'li', 'a', 'span', 'div', 'pre',
        'table', 'tbody', 'tr', 'td', 'th', 'hr', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
        'b', 'i', 'u', 'font', 'img', 'blockquote'
      ],
      ALLOWED_ATTR: [
        'href', 'target', 'rel', 'class', 'style', 'src', 'alt',
        'width', 'height', 'border', 'cellspacing', 'cellpadding',
        'align', 'valign', 'bgcolor', 'color'
      ],
      ALLOW_DATA_ATTR: true
    });

    if (!sanitizedContent.trim()) return null;

    let processedContent = sanitizedContent
      .replace(/<img[^>]+src="cid:[^>]+>/g, '')
      .replace(/<img[^>]+src="[^"]*"[^>]*>/g, (match) => {
        return match.includes('http://') || match.includes('https://') ? match : '';
      });

    const scopedStyles = styleContents
      .map(style => style?.replace(/([^{}]*){([^{}]*)}/g, '.email-content-scope $1{$2}'))
      .join('\n');

    if (highlightText) {
      const container = document.createElement('div');
      container.innerHTML = processedContent;

      const escapedHighlightText = highlightText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const searchRegex = new RegExp(`(${escapedHighlightText})`, 'gi');

      const highlightNodes = (node: Node) => {
        if (node.nodeType === Node.TEXT_NODE && node.textContent) {
          if (searchRegex.test(node.textContent)) {
            const frag = document.createDocumentFragment();
            let lastIndex = 0;
            const currentTextContent = node.textContent;
            currentTextContent.replace(searchRegex, (match, _p1, offset) => {
              const pos = offset;
              if (pos > lastIndex) {
                frag.appendChild(document.createTextNode(currentTextContent.slice(lastIndex, pos)));
              }
              const span = document.createElement('span');
              span.className = 'email-highlight';
              span.textContent = match;
              frag.appendChild(span);
              lastIndex = pos + match.length;
              return match;
            });
            if (lastIndex < currentTextContent.length) {
              frag.appendChild(document.createTextNode(currentTextContent.slice(lastIndex)));
            }
            node.parentNode?.replaceChild(frag, node);
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          node.childNodes.forEach(child => {
            highlightNodes(child);
          });
        }
      };

      highlightNodes(container);
      processedContent = container.innerHTML;
    }

    return (
      <>
        <style>{`
          /* Base container */
          .email-content-scope {
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
            max-width: 100%;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            line-height: 1.6;
            color: #000;
            font-size: 16px;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            padding: 20px;
          }

          /* Reset potential conflicts */
          .email-content-scope * {
            word-wrap: break-word;
            word-break: break-word;
            max-width: 100%;
          }

          .email-content-scope pre,
          .email-content-scope [style*="white-space: pre"] {
            white-space: pre-wrap;
          }

          /* Force text colors */
          .email-content-scope,
          .email-content-scope p,
          .email-content-scope div,
          .email-content-scope span,
          .email-content-scope td,
          .email-content-scope li {
            color: #000 !important;
          }

          /* Headings */
          .email-content-scope h1 {
            font-size: 2em;
            margin: 0.67em 0;
            font-weight: 600;
          }
          .email-content-scope h2 {
            font-size: 1.5em;
            margin: 0.83em 0;
            font-weight: 600;
          }
          .email-content-scope h3 {
            font-size: 1.17em;
            margin: 1em 0;
            font-weight: 600;
          }

          /* Lists */
          .email-content-scope ul,
          .email-content-scope ol {
            padding-left: 2em;
            margin: 0.5em 0;
          }
          .email-content-scope ul {
            list-style-type: disc;
          }
          .email-content-scope ul ul {
            list-style-type: circle;
          }

          /* Links */
          .email-content-scope a {
            color: #0366d6 !important;
            text-decoration: none;
          }
          .email-content-scope a:hover {
            text-decoration: underline;
          }

          /* Blockquotes */
          .email-content-scope blockquote {
            margin: 1em 0;
            padding-left: 1em;
            color: #6a737d;
            border-left: 0.25em solid #dfe2e5;
          }

          /* Force minimum text contrast */
          .email-content-scope [style*="color: #fff"],
          .email-content-scope [style*="color: white"],
          .email-content-scope [style*="color: rgb(255, 255, 255)"] {
            color: #000 !important;
          }

          /* Highlight style */
          .email-content-scope .email-highlight {
            background-color: #fff3cd;
            padding: 2px 0;
            border-radius: 2px;
          }

          /* Inline scoped styles from the email */
          ${scopedStyles}
        `}</style>
        <div className="email-content-scope">
          <div dangerouslySetInnerHTML={{ __html: processedContent }} />
        </div>
      </>
    );
  } catch (error) {
    console.error('HTML sanitization failed:', error);
    return <div className="text-red-500">Error rendering content</div>;
  }
};

const EmailDetailComponent = ({
  emailBody,
  isEmbedded = false,
  emailComponentTitle = 'Email Content',
  highlightText
}: EmailDetailProps) => {
  return (
    <div className={`flex-1 overflow-auto ${isEmbedded ? 'h-full' : 'min-h-screen'} bg-white`}>
      <div className="mx-auto">
        <div className="bg-white h-full overflow-y-auto">
          <div className="p-4 mt-3">
            <div className="flex items-center text-md text-gray-500 mb-2">
              <span className="font-medium text-gray-900">
                {emailComponentTitle}
              </span>
            </div>

            <ScrollArea
              type="auto"
            >
              <div className="email-wrapper">
                <EmailContentErrorBoundary>
                  {sanitizeAndRenderHTML(emailBody, highlightText)}
                </EmailContentErrorBoundary>
              </div>
            </ScrollArea>
          </div>
        </div>
      </div>
    </div>
  );
};

export const EmailDetail = EmailDetailComponent;
