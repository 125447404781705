import { useMemo } from "react";
import { UserMetricResponse, MetricCategory } from "../../services/api";

interface RecentUsageProps {
  metrics: UserMetricResponse | null;
  isLoading: boolean;
  error: string | null;
}

export const RecentUsage = ({ metrics, isLoading, error }: RecentUsageProps) => {
  const metricsData = useMemo(() => {
    if (!metrics) return {
      extract: { count: 0, documents: 0 },
      compare: { count: 0, documents: 0 },
      workflow: { count: 0, documents: 0 },
      submissionIntake: { count: 0, documents: 0 },
      policyCheck: { count: 0, documents: 0 },
      audit: { count: 0, documents: 0 },
      uwAudit: { count: 0, documents: 0 },
      qaAudit: { count: 0, documents: 0 },
      excel: { count: 0, documents: 0 },
      sovExcel: { count: 0, documents: 0 },
      lossRunExcel: { count: 0, documents: 0 }
    };

    const extractMetrics = metrics.metrics.filter(m => m.category === MetricCategory.EXTRACT);
    const compareMetrics = metrics.metrics.filter(m => m.category === MetricCategory.COMPARE);
    const workflowMetrics = metrics.metrics.filter(m => m.category === MetricCategory.WORKFLOW);
    const submissionIntakeMetrics = workflowMetrics.filter(m => m.metadata?.workflow_type === 'submission_intake');
    const policyCheckMetrics = workflowMetrics.filter(m => m.metadata?.workflow_type === 'policy_check');
    const auditMetrics = metrics.metrics.filter(m => m.category === MetricCategory.AUDIT);
    const uwAuditMetrics = auditMetrics.filter(m => m.metadata?.audit_type === 'uw_audit');
    const qaAuditMetrics = auditMetrics.filter(m => m.metadata?.audit_type === 'qa_audit');
    const excelMetrics = metrics.metrics.filter(m => m.category === MetricCategory.EXCEL);
    const sovExcelMetrics = excelMetrics.filter(m => m.metadata?.excel_type === 'sov');
    const lossRunExcelMetrics = excelMetrics.filter(m => m.metadata?.excel_type === 'loss_run');

    return {
      extract: {
        count: extractMetrics.length,
        documents: extractMetrics.reduce((acc, m) => acc + m.document_count, 0)
      },
      compare: {
        count: compareMetrics.length,
        documents: compareMetrics.reduce((acc, m) => acc + m.document_count, 0)
      },
      workflow: {
        count: workflowMetrics.length,
        documents: workflowMetrics.reduce((acc, m) => acc + m.document_count, 0)
      },
      submissionIntake: {
        count: submissionIntakeMetrics.length,
        documents: submissionIntakeMetrics.reduce((acc, m) => acc + m.document_count, 0)
      },
      policyCheck: {
        count: policyCheckMetrics.length,
        documents: policyCheckMetrics.reduce((acc, m) => acc + m.document_count, 0)
      },
      audit: {
        count: auditMetrics.length,
        documents: auditMetrics.reduce((acc, m) => acc + m.document_count, 0)
      },
      uwAudit: {
        count: uwAuditMetrics.length,
        documents: uwAuditMetrics.reduce((acc, m) => acc + m.document_count, 0)
      },
      qaAudit: {
        count: qaAuditMetrics.length,
        documents: qaAuditMetrics.reduce((acc, m) => acc + m.document_count, 0)
      },
      excel: {
        count: excelMetrics.length,
        documents: excelMetrics.reduce((acc, m) => acc + m.document_count, 0)
      },
      sovExcel: {
        count: sovExcelMetrics.length,
        documents: sovExcelMetrics.reduce((acc, m) => acc + m.document_count, 0)
      },
      lossRunExcel: {
        count: lossRunExcelMetrics.length,
        documents: lossRunExcelMetrics.reduce((acc, m) => acc + m.document_count, 0)
      }
    };
  }, [metrics]);

  if (isLoading) {
    return (
      <div className="space-y-8">
        {[1, 2, 3, 4].map((i) => (
          <div key={i} className="flex items-center">
            <div className="space-y-1">
              <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
              <div className="h-4 w-48 bg-gray-200 rounded animate-pulse"></div>
            </div>
            <div className="ml-auto">
              <div className="h-4 w-12 bg-gray-200 rounded animate-pulse"></div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="space-y-8">
      <div>
        <div className="flex items-center justify-between mb-2">
          <span className="text-xs font-medium text-gray-500">Feature</span>
          <span className="text-xs font-medium text-gray-500">Request(s)</span>
        </div>
        <div className="flex items-center">
          <div className="space-y-1">
            <p className="text-sm font-medium leading-none">Compare</p>
            <p className="text-sm text-muted-foreground">
              Processed {metricsData.compare.documents} document(s)
            </p>
          </div>
          <div className="ml-auto font-medium">{metricsData.compare.count}</div>
        </div>
      </div>

      <div className="flex items-center">
        <div className="space-y-1">
          <p className="text-sm font-medium leading-none">Extract</p>
          <p className="text-sm text-muted-foreground">
            Processed {metricsData.extract.documents} document(s)
          </p>
        </div>
        <div className="ml-auto font-medium">{metricsData.extract.count}</div>
      </div>

      <div className="space-y-4">
        <div className="flex items-center">
          <div className="space-y-1">
            <p className="text-sm font-medium leading-none">Workflow</p>
            <p className="text-sm text-muted-foreground">
              Processed {metricsData.workflow.documents} document(s)
            </p>
          </div>
          <div className="ml-auto font-medium">{metricsData.workflow.count}</div>
        </div>

        <div className="flex items-center pl-4 border-l-2 border-gray-200">
          <div className="space-y-1">
            <p className="text-sm font-medium leading-none text-muted-foreground">Submission Intake</p>
            <p className="text-sm text-muted-foreground">
              Processed {metricsData.submissionIntake.documents} document(s)
            </p>
          </div>
          <div className="ml-auto font-medium text-muted-foreground">{metricsData.submissionIntake.count}</div>
        </div>

        <div className="flex items-center pl-4 border-l-2 border-gray-200">
          <div className="space-y-1">
            <p className="text-sm font-medium leading-none text-muted-foreground">Policy Check</p>
            <p className="text-sm text-muted-foreground">
              Processed {metricsData.policyCheck.documents} document(s)
            </p>
          </div>
          <div className="ml-auto font-medium text-muted-foreground">{metricsData.policyCheck.count}</div>
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex items-center">
          <div className="space-y-1">
            <p className="text-sm font-medium leading-none">Audit</p>
            <p className="text-sm text-muted-foreground">
              Processed {metricsData.audit.documents} document(s)
            </p>
          </div>
          <div className="ml-auto font-medium">{metricsData.audit.count}</div>
        </div>

        <div className="flex items-center pl-4 border-l-2 border-gray-200">
          <div className="space-y-1">
            <p className="text-sm font-medium leading-none text-muted-foreground">UW Audit</p>
            <p className="text-sm text-muted-foreground">
              Processed {metricsData.uwAudit.documents} document(s)
            </p>
          </div>
          <div className="ml-auto font-medium text-muted-foreground">{metricsData.uwAudit.count}</div>
        </div>

        <div className="flex items-center pl-4 border-l-2 border-gray-200">
          <div className="space-y-1">
            <p className="text-sm font-medium leading-none text-muted-foreground">UW Audit Q&A</p>
            <p className="text-sm text-muted-foreground">
              Processed {metricsData.qaAudit.documents} document(s)
            </p>
          </div>
          <div className="ml-auto font-medium text-muted-foreground">{metricsData.qaAudit.count}</div>
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex items-center">
          <div className="space-y-1">
            <p className="text-sm font-medium leading-none">Excel</p>
            <p className="text-sm text-muted-foreground">
              Processed {metricsData.excel.documents} document(s)
            </p>
          </div>
          <div className="ml-auto font-medium">{metricsData.excel.count}</div>
        </div>

        <div className="flex items-center pl-4 border-l-2 border-gray-200">
          <div className="space-y-1">
            <p className="text-sm font-medium leading-none text-muted-foreground">SOV</p>
            <p className="text-sm text-muted-foreground">
              Processed {metricsData.sovExcel.documents} document(s)
            </p>
          </div>
          <div className="ml-auto font-medium text-muted-foreground">{metricsData.sovExcel.count}</div>
        </div>

        <div className="flex items-center pl-4 border-l-2 border-gray-200">
          <div className="space-y-1">
            <p className="text-sm font-medium leading-none text-muted-foreground">Loss Run</p>
            <p className="text-sm text-muted-foreground">
              Processed {metricsData.lossRunExcel.documents} document(s)
            </p>
          </div>
          <div className="ml-auto font-medium text-muted-foreground">{metricsData.lossRunExcel.count}</div>
        </div>
      </div>
    </div>
  );
};