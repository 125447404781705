import { Dialog, DialogContent } from "../ui/dialog"
import { Button } from "../ui/button"
import { PlusCircle, ArrowRight, FileText, X, CloudUpload } from "lucide-react"
import { useRef, useState } from "react"
import { ScrollArea, ScrollBar } from "../ui/scroll-area"
import { cn } from "../../lib/utils"
import { UserDocument, UserDocumentUploadRequest } from "../../services/api"
import toast from "react-hot-toast"
import sharepointimg from '../../assets/icons/sharepoint.svg';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "../ui/dropdown-menu"
import { useSharePoint } from '../../lib/sharepoint';


interface WorkflowDialogProps {
  isOpen: boolean
  onClose: () => void
  onSend: (message: string, documentIds: string[]) => Promise<void>
  uploadDocument: (file: File, request: UserDocumentUploadRequest) => Promise<{ user_document_id: string; sas_url: string }>
  deleteDocument: (documentId: string) => Promise<void>
  sharePointHostname: string
}

export function WorkflowDialog({
  isOpen,
  onClose,
  onSend,
  uploadDocument,
  deleteDocument,
  sharePointHostname
}: WorkflowDialogProps) {
  const [message, setMessage] = useState("")
  const [uploadedDocuments, setUploadedDocuments] = useState<UserDocument[]>([])
  const [isSending, setIsSending] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { selectAndUploadFile, isLoading: isSharePointLoading } = useSharePoint(
    uploadDocument,
    setUploadedDocuments,
    process.env.REACT_APP_SHAREPOINT_CLIENT_ID || '',
    process.env.REACT_APP_TENANT_ID || '',
    sharePointHostname || 'https://example.com'
  );

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files
    if (newFiles) {
      setIsUploading(true)
      try {
        const validFiles = Array.from(newFiles).filter(file => {
          const fileType = file.type.toLowerCase();
          const fileName = file.name.toLowerCase();
          return fileType === 'application/pdf' ||
            fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            fileType === 'message/rfc822' ||
            fileType === 'application/vnd.ms-outlook' ||
            fileType === 'application/zip' ||
            fileType === 'application/x-zip-compressed' ||
            fileType === 'image/jpeg' ||
            fileType === 'image/png' ||
            fileName.endsWith('.eml') ||
            fileName.endsWith('.msg') ||
            fileName.endsWith('.zip');
        });

        for (const file of validFiles) {
          try {
            const uploadRequest: UserDocumentUploadRequest = {
              filename: file.name,
              blob_url: "",
              origin: "upload",
              category: "workflow",
              owner_uid: "",
              owner_oid: "",
              run_id: "",
            };

            const { user_document_id, sas_url } = await uploadDocument(file, uploadRequest);
            setUploadedDocuments(prev => [...prev, { _id: user_document_id, filename: file.name, blob_url: sas_url } as UserDocument]);
          } catch (error) {
            console.error(`Failed to upload file ${file.name}:`, error);
            toast.error(`Failed to upload file ${file.name}`);
          }
        }

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } catch (error) {
        console.error(`Failed to upload file:`, error);
        toast.error(`Failed to upload file`);
      } finally {
        setIsUploading(false)
      }
    }
  }

  const removeFile = async (fileName: string) => {
    const documentToRemove = uploadedDocuments.find(doc => doc.filename === fileName);
    if (documentToRemove) {
      try {
        await deleteDocument(documentToRemove._id);
        setUploadedDocuments((prevDocs) => prevDocs.filter((doc) => doc._id !== documentToRemove._id));

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } catch (error) {
        console.error(`Failed to delete document ${fileName}:`, error);
        toast.error(`Failed to delete document ${fileName}`);
      }
    }
  }

  const handleSend = async () => {
    if (!message.trim() && uploadedDocuments.length === 0) return

    setIsSending(true)
    try {
      await onSend(message, uploadedDocuments.map(doc => doc._id))
      setMessage("")
      setUploadedDocuments([])
      onClose()
    } catch (error) {
      console.error('Failed to send:', error)
      toast.error('Failed to send message')
    } finally {
      setIsSending(false)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
      e.preventDefault();
      if (message.trim() && !isSending && !isUploading) {
        handleSend();
      }
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[650px] w-[95vw] max-h-[45vh] flex flex-col overflow-hidden p-6 rounded-lg [&>button]:hidden">
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={handleFileUpload}
          multiple
          accept=".jpg,.jpeg,.png,.msg,.eml,.pdf,.xlsx,.docx,.zip"
        />
        <div className="flex-1 flex flex-col">
          <div className="flex-1 rounded-lg border border-gray-200 bg-white p-4">
            <ScrollArea className="h-[180px] w-full pr-4">
              <div
                contentEditable
                className={cn(
                  "outline-none text-base text-gray-900",
                  "empty:before:content-[attr(data-placeholder)] before:text-gray-500 before:pointer-events-none"
                )}
                data-placeholder="Type your message here"
                onInput={(e) => setMessage(e.currentTarget.textContent || "")}
                onKeyDown={handleKeyDown}
                role="textbox"
                aria-multiline="true"
                suppressContentEditableWarning
              />
            </ScrollArea>

            <div className="flex flex-col mt-4 pt-4 border-t border-gray-100">
              <div className="flex items-center gap-2">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="outline"
                      size="sm"
                      className="flex-shrink-0"
                      disabled={isUploading || isSharePointLoading}
                    >
                      <PlusCircle className="h-4 w-4 mr-2" />
                      Add attachments
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="start" className="w-[240px] p-1 bg-white">
                    <DropdownMenuItem
                      onSelect={selectAndUploadFile}
                      disabled={isSharePointLoading || isUploading}
                    >
                      <div className="flex items-center">
                        <img src={sharepointimg} alt="SharePoint" className="h-4 w-4 mr-2" />
                        <span>
                          {isSharePointLoading
                            ? 'Uploading...'
                            : 'Upload from SharePoint'
                          }
                        </span>
                      </div>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={() => fileInputRef.current?.click()}>
                      <div className="flex items-center">
                        <CloudUpload className="h-4 w-4 mr-2" />
                        <span>Upload from computer</span>
                      </div>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>

                <ScrollArea className="flex-1">
                  <div className="flex items-center gap-2 w-full whitespace-nowrap">
                    {uploadedDocuments.map((document) => (
                      <div
                        key={document._id}
                        className="inline-flex items-center text-gray-900 border border-gray-300 rounded-md text-sm bg-white hover:bg-gray-50 transition-colors px-3 py-2 h-9 flex-shrink-0"
                      >
                        <div className="flex items-center gap-3 flex-1 min-w-0">
                          <div className="w-7 h-7 rounded-lg bg-purple-50 flex-shrink-0 flex items-center justify-center">
                            <FileText className="w-4 w-4 text-purple-600" />
                          </div>
                          <span className="truncate max-w-[150px]">{document.filename}</span>
                        </div>
                        <button
                          onClick={() => removeFile(document.filename)}
                          className="ml-2 text-gray-500 hover:text-gray-900"
                        >
                          <X className="h-4 w-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                  <ScrollBar orientation="horizontal" />
                </ScrollArea>

                <Button
                  onClick={handleSend}
                  disabled={isSending || (!message.trim() && uploadedDocuments.length === 0) || isUploading}
                  size="icon"
                  className="rounded-full h-10 w-10 flex-shrink-0"
                >
                  <ArrowRight className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
