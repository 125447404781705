import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { CitationWithState } from '../../services/api';
import { X, ChevronLeft, ChevronRight, Languages, Search, ChevronUp, ChevronDown } from 'lucide-react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { highlightPlugin, Trigger } from '@react-pdf-viewer/highlight';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { ResizablePanelGroup, ResizablePanel, ResizableHandle } from '../ui/resizable';
import { cn } from '../../lib/utils';
import { searchPlugin } from '@react-pdf-viewer/search';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

interface PDFPreviewSliderProps {
  isOpen: boolean;
  onClose: () => void;
  pdfUrl: string | null;
  citations: CitationWithState[] | null;
  isLoading: boolean;
  fileType?: 'pdf' | 'excel';
  originalPdfUrl?: string | null;
}

interface HighlightArea {
  pageIndex: number;
  left: number;
  top: number;
  width: number;
  height: number;
  color: string;
}

export const PDFPreviewSlider: React.FC<PDFPreviewSliderProps> = React.memo(({
  isOpen,
  onClose,
  pdfUrl,
  citations,
  isLoading,
  fileType = 'pdf',
  originalPdfUrl,
}) => {
  const [currentHighlightIndex, setCurrentHighlightIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [showTranslation, setShowTranslation] = useState(true);
  const [showSearch, setShowSearch] = useState(false);

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  const searchPluginInstance = searchPlugin();
  const { Search: SearchComponent, clearHighlights } = searchPluginInstance;


  const highlights: HighlightArea[] = useMemo(() => {
    if (!Array.isArray(citations)) {
      return [];
    }
    return citations.map((citationWithState) => ({
      pageIndex: citationWithState.citation.bbox.page - 1,
      left: citationWithState.citation.bbox.left * 100,
      top: citationWithState.citation.bbox.top * 100,
      height: citationWithState.citation.bbox.height * 100,
      width: citationWithState.citation.bbox.width * 100,
      color: citationWithState.state === 'match' ? 'rgba(0, 255, 0, 0.3)' : 'rgba(255, 0, 0, 0.3)', // tweak 4th value to change opacity
    }));
  }, [citations]);

  const sortedHighlights = useMemo(() =>
    [...highlights].sort((a, b) => a.pageIndex - b.pageIndex || a.top - b.top),
    [highlights]
  );

  const navigateHighlight = useCallback((direction: 'next' | 'prev') => {
    if (sortedHighlights.length === 0) return;
    const newIndex = direction === 'next'
      ? (currentHighlightIndex + 1) % sortedHighlights.length
      : (currentHighlightIndex - 1 + sortedHighlights.length) % sortedHighlights.length;

    setCurrentHighlightIndex(newIndex);
    jumpToPage(sortedHighlights[newIndex].pageIndex);
  }, [currentHighlightIndex, sortedHighlights, jumpToPage]);

  const renderHighlights = useCallback((props: { pageIndex: number }) => {
    return (
      <div>
        {sortedHighlights
          .filter((area) => area.pageIndex === props.pageIndex)
          .map((area, idx) => (
            <div
              key={idx}
              className="highlight-area"
              style={{
                position: 'absolute',
                left: `${area.left}%`,
                top: `${area.top}%`,
                width: `${area.width}%`,
                height: `${area.height}%`,
                background: area.color,
                mixBlendMode: 'multiply',
                pointerEvents: 'none',
              }}
            />
          ))}
      </div>
    );
  }, [sortedHighlights]);

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const safeCurrentIndex = useMemo(() => {
    return Math.min(currentHighlightIndex, sortedHighlights.length - 1);
  }, [currentHighlightIndex, sortedHighlights]);

  useEffect(() => {
    setShowTranslation(true);
  }, [pdfUrl]);

  useEffect(() => {
    if (!showSearch) {
      clearHighlights();
    }
  }, [showSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && showSearch) {
        setShowSearch(false);
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [showSearch]);

  const renderContent = () => {
    if (!pdfUrl || isLoading) return null;

    const currentPdfUrl = showTranslation ? pdfUrl : (originalPdfUrl || pdfUrl);

    if (fileType === 'excel') {
      const encodedUrl = encodeURIComponent(currentPdfUrl);
      const viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`;
      return (
        <iframe
          src={viewerUrl}
          className="w-full h-full border-0"
          title="Excel Preview"
        />
      );
    }

    return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <div className="relative h-full w-full">
          <div className="absolute top-4 left-4 z-10 flex items-center gap-2">
            <button
              onClick={() => setShowSearch(!showSearch)}
              className="text-black hover:text-gray-700 focus:outline-none transition-colors bg-gray-400/90 backdrop-blur-sm rounded-full p-1.5"
              aria-label="Toggle search"
            >
              <Search className="h-5 w-5" />
            </button>
          </div>
          {showSearch && (
            <div className="absolute top-14 left-4 z-10">
              <SearchComponent>
                {(props) => (
                  <div className="rpv-search__container bg-gray-600/90 backdrop-blur-sm rounded-lg p-2">
                    <div className="flex items-center gap-2">
                      <div className="rpv-search__input relative flex-1">
                        <input
                          placeholder="Search..."
                          type="text"
                          value={props.keyword}
                          onChange={(e) => {
                            props.setKeyword(e.target.value);
                            props.search();
                          }}
                          className="rpv-search__input bg-white/30 backdrop-blur-sm rounded-lg px-3 py-1.5 text-black placeholder-gray-600 w-full"
                        />
                        {props.keyword && (
                            <div className="absolute right-2 top-1/2 -translate-y-1/2 text-xs text-gray-600">
                              {props.numberOfMatches > 0
                                ? `${Math.min(props.currentMatch + 1, props.numberOfMatches)}/${props.numberOfMatches}`
                                : '0/0'}
                            </div>
                        )}
                      </div>
                      <div className="h-7 w-px bg-gray-300/30" />
                      <div className="flex gap-1">
                        <button
                          className="p-1.5 bg-gray-400/90 hover:bg-white/30 backdrop-blur-sm rounded-lg transition-colors"
                          onClick={() => props.jumpToPreviousMatch()}
                          aria-label="Previous match"
                        >
                          <ChevronUp className="h-4 w-4 text-black" />
                        </button>
                        <button
                          className="p-1.5 bg-gray-400/90 hover:bg-white/30 backdrop-blur-sm rounded-lg transition-colors"
                          onClick={() => props.jumpToNextMatch()}
                          aria-label="Next match"
                        >
                          <ChevronDown className="h-4 w-4 text-black" />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </SearchComponent>
            </div>
          )}
          <Viewer
            fileUrl={currentPdfUrl}
            plugins={[
              highlightPluginInstance,
              pageNavigationPluginInstance,
              searchPluginInstance
            ]}
            defaultScale={SpecialZoomLevel.PageFit}
            initialPage={sortedHighlights.length > 0 ? sortedHighlights[0].pageIndex : 0}
            theme="dark"
            onDocumentLoad={() => {
              if (sortedHighlights.length > 0) {
                jumpToPage(sortedHighlights[0].pageIndex);
              }
            }}
          />
        </div>
      </Worker>
    );
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-hidden bg-black bg-opacity-30">
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel className="bg-transparent" collapsible={false} minSize={25}>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel className="bg-white shadow-xl" minSize={40.5}>
          <div ref={containerRef} className="relative h-full w-full">
            {originalPdfUrl && fileType === 'pdf' && (
              <div className="absolute top-4 right-14 z-10">
                <button
                  onClick={() => setShowTranslation(!showTranslation)}
                  className={cn(
                    "px-3 py-1.5 text-sm font-medium rounded-full transition-all duration-200",
                    "flex items-center gap-1.5",
                    "bg-gray-400/90 backdrop-blur-sm hover:bg-white/30",
                    "text-black hover:text-black",
                    "border border-white/20",
                    "shadow-lg"
                  )}
                >
                  <span className="text-xs">
                    {showTranslation ? "Translated" : "Original"}
                  </span>
                  <Languages className="w-3 h-3" />
                </button>
              </div>
            )}
            {/* Bottom right button */}
            {/* {originalPdfUrl && fileType === 'pdf' && (
            <div className="absolute bottom-4 right-4 z-10">
              <button
                onClick={() => setShowTranslation(!showTranslation)}
                className={cn(
                  "px-3 py-1.5 text-sm font-medium rounded-full transition-all duration-200",
                  "flex items-center gap-1.5",
                  "bg-white/20 backdrop-blur-sm hover:bg-white/30",
                  "text-white hover:text-white",
                  "border border-white/20",
                  "shadow-lg",
                  "bg-gradient-to-r from-purple-600/80 to-purple-500/80",
                  "hover:from-purple-600/90 hover:to-purple-500/90"
                )}
              >
                <span className="text-xs">
                  {showTranslation ? "Translated" : "Original"}
                </span>
                <Languages className="w-3 h-3" />
              </button>
              </div>
            )} */}
            <button
              onClick={onClose}
              className="absolute top-4 right-4 z-10 text-black hover:text-gray-700 focus:outline-none transition-colors bg-white/20 backdrop-blur-sm rounded-full p-1.5"
              aria-label="Close preview"
            >
              <X className="h-5 w-5" />
            </button>
            <div className="w-full h-full">
              {renderContent()}
            </div>
            {fileType === 'pdf' && sortedHighlights.length > 0 && (
              <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex items-center space-x-4 bg-white/20 backdrop-blur-sm rounded-full px-4 py-2">
                <button
                  onClick={() => navigateHighlight('prev')}
                  className="text-black hover:text-gray-700 focus:outline-none transition-colors"
                  aria-label="Previous highlight"
                >
                  <ChevronLeft className="h-5 w-5" />
                  <span className="sr-only">Previous</span>
                </button>
                <div className="text-sm text-black">
                  Highlight {sortedHighlights.length > 0 ? safeCurrentIndex + 1 : 0} of {sortedHighlights.length}
                </div>
                <button
                  onClick={() => navigateHighlight('next')}
                  className="text-black hover:text-gray-700 focus:outline-none transition-colors"
                  aria-label="Next highlight"
                >
                  <ChevronRight className="h-5 w-5" />
                  <span className="sr-only">Next</span>
                </button>
              </div>
            )}
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
});

PDFPreviewSlider.displayName = 'PDFPreviewSlider';
