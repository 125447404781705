import { useMemo } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { UserMetricResponse } from "../../services/api";
import { Card } from "../ui/card";

export function Overview({ metrics }: { metrics: UserMetricResponse | null }) {
  const chartData = useMemo(() => {
    if (!metrics) return [];

    const dataByMonth: Record<string, number> = {};

    metrics.metrics.forEach(metric => {
      const date = new Date(metric.created_at + "Z");
      const monthKey = date.toLocaleString('default', { month: 'short' });

      dataByMonth[monthKey] = (dataByMonth[monthKey] || 0) + metric.document_count;
    });

    return Object.entries(dataByMonth).map(([name, total]) => ({
      name,
      total
    }));
  }, [metrics]);

  return (
    <div className="space-y-4">
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        <Card className="p-4">
          <div className="text-sm font-medium text-muted-foreground">
            Total Documents
          </div>
          <div className="text-2xl font-bold">
            {metrics?.total_documents || 0}
          </div>
        </Card>
        <Card className="p-4">
          <div className="text-sm font-medium text-muted-foreground">
            Total Requests
          </div>
          <div className="text-2xl font-bold">
            {metrics?.total_requests || 0}
          </div>
        </Card>
      </div>

      <ResponsiveContainer width="100%" height={350}>
        <BarChart data={chartData}>
          <XAxis
            dataKey="name"
            stroke="#888888"
            fontSize={12}
            tickLine={false}
            axisLine={false}
          />
          <YAxis
            stroke="#888888"
            fontSize={12}
            tickLine={false}
            axisLine={false}
            tickFormatter={(value) => `${value}`}
          />
          <Bar
            dataKey="total"
            fill="currentColor"
            radius={[4, 4, 0, 0]}
            className="fill-primary"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}