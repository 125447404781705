import { useState } from 'react';
import { useNodeApi } from '../../hooks/useNodeApi';
import { GenericTable } from './GenericTable';
import { Citation, TableResponse } from '../../types/table';
import { toast } from 'react-hot-toast';
import { WithLoggedInAuthInfoProps, withRequiredAuthInfo } from '@propelauth/react';

interface TableContainerBaseProps {
  tableData: TableResponse;
  className?: string;
  filteredRows?: number[];
}

type TableContainerProps = TableContainerBaseProps & WithLoggedInAuthInfoProps;

export const TableContainer = withRequiredAuthInfo(({
    accessToken,
    tableData,
    className,
    filteredRows
  }: TableContainerProps) => {
  const [currentPdfUrl, setCurrentPdfUrl] = useState<string | null>(null);
  const { getPdfUrl } = useNodeApi(accessToken);

  const handleCitationClick = async (citations: Citation[]) => {
    if (!currentPdfUrl && citations[0]) {
      try {
        const url = await getPdfUrl(citations[0].document);
        setCurrentPdfUrl(url);
      } catch (error) {
        console.error('Failed to fetch PDF URL:', error);
        toast.error('Failed to load PDF preview');
      }
    }
  };

  return (
    <div className={className}>
      <GenericTable
        headers={tableData.result.headers}
        rows={tableData.result.rows}
        onCitationClick={handleCitationClick}
        pdfUrl={currentPdfUrl}
        filteredRows={filteredRows}
        title={tableData.metadata?.title}
      />
    </div>
  );
});
